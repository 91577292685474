
<template>
  <div class="view_data">
    <el-card class="viewDataCard">
      <div class="header">
        <el-button class="button" @click="exportPlacement">导出数据</el-button>
      </div>
      <el-table
        :data="importDataList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
          fontWeight: 'normal',
          height: '60px',
          color: '#666666',
          background: '#F6F6F6',
          fontSize: '16px',
        }"
        :cell-style="{ width: '120px', height: '60px', }"
      >
        <el-table-column align="center" prop="order_date" label="订单日期"> </el-table-column>
        <el-table-column align="center" prop="sell_amout" label="销售额(元)">
        </el-table-column>
        <el-table-column align="center" prop="order_num" label="订单数量"> </el-table-column>
        <el-table-column align="center" prop="user_inc_num" label="用户增长">
        </el-table-column>
        <el-table-column align="center" prop="average_order_price" label="平均订单金额">
        </el-table-column>
        <el-table-column align="center" prop="conversion_rate" label="订单转化率">
            <template slot-scope="scoped">
                {{ scoped.row.conversion_rate +'%' }}
                </template>
        </el-table-column>
        <el-table-column align="center" prop="refund_rate" label="退货率"> 
            <template slot-scope="scoped">
                {{ scoped.row.refund_rate +'%' }}
                </template>

        </el-table-column>
        <el-table-column align="center" prop="consumer_satisfaction_rate" label="客户满意度">
        </el-table-column>
        <el-table-column align="center" prop="advertising_investment" label="广告投入">
        </el-table-column>
        <el-table-column align="center" prop="purchasing_cost" label="采购成本">
        </el-table-column>
        <el-table-column align="center" prop="roi" label="ROI"> </el-table-column>
      </el-table>
   <div class="footet-pagination">
    <div class="total">
共{{ total }}项数据
    </div>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="form.page"
        :page-size="10"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
   </div>
    </el-card>
  </div>
</template>

<script>
import { stuEcommerceDataReportGetStatisticalData } from "@/utils/apis";
import axios from 'axios'
export default {
  name: "view_data",
  data() {
    return {
      importDataList: [],
      form: {
        limit: 10,
        page: 1,
      },
      total: 0,
    };
  },
  mounted() {
    this.getImportData();
    console.log(this.$route.query.op_id,"拿到这个id");
  },
  methods: {
    getImportData() {
      stuEcommerceDataReportGetStatisticalData(this.form).then((res) => {
        console.log(res.data, "导入的数据");
        if (res.code == 200) {
          this.importDataList = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.form.page = val;

      this.getImportData();
    },
    exportPlacement() {
      // if (!this.$route.query.op_id) {
        if (!this.$route.query.op_id) {
        this.$message.warning("实操题id不存在");
      } else {
        let params={
          id:this.$route.query.op_id
        }
      let _this = this;
      axios({
        method: "get",
        params: params,
        url: "stuEcommerceDataReport/exportStatisticalData",
        responseType: "blob",
        headers: { Authorization: _this.teacherToken },
      })
        .then((res) => {
          let filename = "电子商务数据.xlsx";
          let blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
          }); //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
          let downloadElement = document.createElement("a");
          let href = window.URL.createObjectURL(blob); //创建下载的链接
          downloadElement.href = href;
          downloadElement.download = filename; //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
        })}
    
    },
  },
};
</script>

<style scoped lang="scss">
.view_data {
  width: 100%;
  height: 100%;
  padding: 30px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  .el-card {
    flex: 1;
    padding: 30px;
  }
}
.header {

margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}
.button {
  color: #fff;
  background-color: #1cb064;
  border-color: #1cb064;
}
.el-table {
  border-radius: 4px;
}
.footet-pagination{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .total{
        margin-left: 20px;
        color: #666666;
       
    }

}


</style>